<template>
  <div v-show="info && info.volunteer_id">
    <div class="detail-info-label" v-if="showLabel">可领取的志工列表</div>
    <a-table :columns="columns" row-key="id" :data-source="list" class="volunteer-table"></a-table>
  </div>
</template>

<script>
export default {
  name: "VolunteerList",
  props: {
    info: Object,
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      columns: [
        { title: "志工名称", dataIndex: "name" },
        { title: "志工身份证", dataIndex: "id_card" },
      ],
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      let volunteer_id = this.info.volunteer_id;
      // console.log("volunteer_id:", volunteer_id);
      if (volunteer_id) {
        this.$axios({
          url: "/admin/volunteer/list",
          method: "POST",
          data: { ids: volunteer_id },
        }).then((res) => {
          if (res.error == 0) {
            this.list = res.data;
          } else {
            this.$message.warning(res.msg);
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.detail-info-label {
  font-size: 16px;
  padding-top: 6px;
}
.volunteer-table {
  margin-top: 6px;;
}
</style>
