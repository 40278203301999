<template>
  <div class="holiday-other" v-if="info && info.h_type == 1">
    <div class="detail-info-item">
      <div class="detail-info-label">假期额度</div>
      <div class="detail-info-value">{{ info.is_limit == 1 ? `${info.count}天` : '不限额' }}</div>
    </div>
    <div class="detail-info-item">
      <div class="detail-info-label">额度重置</div>
      <div class="detail-info-value">{{ info.is_auto == 1 ? `每年${info.date[0]}月${info.date[1]}日自动重置` : '需手动重置' }}</div>
    </div>
    <div class="holiday-other-operate">
      <a-button type="primary" @click="generate" :loading="loading">{{loading ? '重置中' : '重置假期余额'}}</a-button>
      <div class="holiday-other-tip">
        <a-icon type="info-circle" />
        <div class="holiday-tip-text">温馨提示：点击后所有人员的该项假期时长会全部重置，该年度已使用的假期会被覆盖，若需要改变个人假期余额，请到假期余额中单独修改。</div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "HolidayOther",
        props: {
            info: Object
        },
        data() {
            return {
                loading: false
            }
        },
        methods: {
            generate() {
                this.$confirm({
                    title: '提示',
                    content: `重置后，所有成员${this.info.name}余额将被重置为设的的假期余额，确定重置此假期余额吗？`,
                    onOk: () => {
                        const url = "/admin/holiday-count/generate";
                        this.$axios({
                            url,
                            method: 'POST',
                            data: {
                                id: this.info.id
                            },
                            noTempleFilter: true
                        }).then(res => {
                            if(res.error == 0) {
                                this.$message.success("假期余额已重置");
                            } else {
                                this.$message.warning(res.msg);
                            }
                        }).finally(() => {
                            this.loading = false;
                        })
                    },
                });
            }
        }
    }
</script>

<style scoped lang="less">
  .holiday-other-operate {
    margin-top: 12px;
  }
  .holiday-other-tip {
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: @orange-6;
  }
  .holiday-tip-text {
    margin-left: .5em;
  }
</style>
