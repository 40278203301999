<template>
  <div class="notice-other" v-if="info">
    <div class="detail-info-item">
      <div class="detail-info-label">{{ info.need_confirm == 1 ? "确认情况" : "阅读情况"}}</div>
      <div class="detail-info-value">
        <p>{{ info.need_confirm == 1 ? info.confirmCount : info.readCount }} / {{ info.visible_ids.split(",").length}}</p>
      </div>
    </div>
    <a-space class="notice-confirm">
      <a-button disabled v-if="info.confirmObj">已确认</a-button>
      <a-button @click="confirmNotice" v-else-if="info.need_confirm == 1">确认</a-button>
      <a-button type="danger" @click="deleteNotice" v-if="info.user_id == wxId">删除</a-button>
    </a-space>
  </div>
</template>

<script>
    import {getUserWxId} from "../../common/js/storage";
    import axios from "../../common/js/req";

    export default {
        name: "NoticeOther",
        props: {
            info: Object
        },
        data() {
            return {
                list: [],
                loading: false,
                wxId: null
            }
        },
        watch: {
            info() {
                this.setReadState();
            }
        },
        created() {
            this.wxId = getUserWxId();
            this.setReadState();
        },
        methods: {
            setReadState() {
                const info = this.info;
                // 不需要确认进入详情页变为已读
                if(info && info.need_confirm == 0 && !info.readObj) {
                    this.$axios({
                        url: "/admin/notice-read",
                        method: "POST",
                        data: {
                            notice_id: info.id,
                            user_id: getUserWxId()
                        },
                    }).then(res => {
                        this.$set(this.info, "readObj", res);
                        this.$set(this.info, "readCount", this.info.readCount * 1 + 1);
                        this.$store.commit("changeUpdate", {type: "notice"});
                    });
                }
            },
            confirmNotice() {
                this.loading = true;
                this.$axios({
                    url: "/admin/notice-confirm",
                    method: "POST",
                    data: {
                        notice_id: this.info.id,
                        user_id: getUserWxId()
                    }
                }).then(res => {
                    this.$set(this.info, "confirmObj", res);
                    this.$set(this.info, "confirmCount", this.info.confirmCount * 1 + 1);
                    this.$store.commit("changeUpdate", {type: "notice"});
                }).finally(() => this.loading = false);
            },
            deleteNotice() {
                this.$confirm({
                    title: '提示',
                    content: "确定删除此公告吗？",
                    onOk: () => {
                        axios({
                            url: `/admin/notice/${this.info.id}`,
                            method: "PATCH",
                            data: {
                                is_del: 1
                            }
                        }).then(() => {
                            this.$store.commit("changeUpdate", {type: "notice"});
                            this.$router.go(-1)
                        });
                    }
                });
            }
        }
    }
</script>

<style lang="less">
.notice-confirm {
  margin-top: 16px;
  width: 100%;
  justify-content: center;
  .ant-btn {
    width: 120px;
  }
}
</style>
