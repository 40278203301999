<template>
  <div class="buddha-tk-other">
    <div class="other-title">法师列表</div>
    <data-list
      ref="dataList"
      url="/admin/master"
      :query="`&temple_id=${getTemple()}&filter[fs_tangkou_id]=${info.id}`"
      entity-name="法师"
      :add-btn="false"
      hide-show-detail
      hide-edit
      hide-delete
      :height="500"
      :action="action"
      :columns="columns"
      :search-key-type="{name: 2}"
      :request-config="{noTempleFilter: true}"
      v-if="info">
      <template v-slot:button>
        <entity-picker
          btn-text="选择法师"
          multiple
          url="/admin/master"
          :query="`&temple_id=${getTemple()}&filter[or][0][fs_tangkou_id][neq]==${info.id}&filter[or][1][is]=null`"
          no-temple-filter
          :columns="selectColumns"
          :search-key-type="{name: 2}"
          @change="handleMasterChange"
          style="display:inline-block;margin-left: 8px">
          <template v-slot:query="{form}">
            <a-form-model-item label="">
              <a-input v-model="form.name" placeholder="输入法名查询"/>
            </a-form-model-item>
          </template>
          <a-button type="primary">添加法师</a-button>
        </entity-picker>
      </template>
      <template v-slot:query="{form}">
        <a-form-model-item label="">
          <a-input v-model="form.name" placeholder="输入法名查询"></a-input>
        </a-form-model-item>
      </template>
      <template v-slot:action="{item}">
        <a-popconfirm title="确定移除？" ok-text="确定" cancel-text="取消" @confirm="removeMaster(item)">
          <a class="txt-btn danger">移除</a>
        </a-popconfirm>
      </template>
    </data-list>
  </div>
</template>

<script>
    import {getTemple} from "../../common/js/storage";
    import entityPicker from "../../components/controls/entity-picker";

    export default {
        name: "BuddhaTkOther",
        props: {
            info: Object
        },
        components: {
            entityPicker,
        },
        data() {
            return {
                columns: [
                    {title: '法名',dataIndex: 'name'},
                    {title: '手机号码',dataIndex: 'mobile'},
                    {title: '堂口', dataIndex: 'tangkou', customRender: text => text?.name || "--"},
                    {title: '技能', dataIndex: 'ability'},
                    {title: '参与佛事次数', dataIndex: 'fs_count'},
                ],
                selectColumns: [
                    {title: '法名',dataIndex: 'name'},
                    {title: '手机号码',dataIndex: 'mobile'},
                    {title: '堂口', dataIndex: 'tangkou', customRender: text => text?.name || "--"},
                ],
                list: []
            }
        },
        computed: {
            action() {
                return this.$route.meta.action;
            }
        },
        methods: {
            getTemple,
            handleMasterChange(val) {
                if(val) {
                    const ids = val.map(item => item.id).join(",");
                    this.$axios.post("/admin/fs-tangkou/batch-update", {
                        ids,
                        tangkou_id: this.info.id
                    }).then(() => this.updateList());
                }
            },
            updateList() {
                this.$refs.dataList.getList();
            },
            removeMaster(item) {
                const url = `/admin/master/${item.id}`;
                this.$axios({
                    url,
                    method: "PATCH",
                    data: {
                        fs_tangkou_id: null,
                    }
                }).then(() => this.updateList());
            }
        }
    }
</script>

<style lang="less">
.buddha-tk-other {
  margin-top: 16px;
}
</style>
