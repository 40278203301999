<template>
    <div class="hr-program-other">
        <div class="flex-box align-center">
            <div class="other-title">其他</div>
            <a-button @click="generate" :loading="loading">{{loading ? '生成中' : '生成评价'}}</a-button>
        </div>
        <a-modal :width="564" v-model="visible" title="生成评价" ok-text="确认" cancel-text="取消" @ok="confirmGenerate">
            <form-area
                ref="generateForm"
                class="flex-box vertical"
                layout="horizontal"
                hide-btn
                :label-col="{span: 6}"
                :wrapper-col="{span: 18}"
                :items="items"
                :entity="form"></form-area>
        </a-modal>
    </div>
</template>

<script>
    export default {
        name: "HRProgramOther",
        props: {
            info: Object
        },
        data() {
            return {
                visible: false,
                loading: false,
                form: {},
                items: [
                    {
                        key: 'title',
                        label: '评价名称',
                        component: 'a-input',
                        props: {
                            placeholder: '请输入评价名称',
                        },
                        rules: [{ required: true, message: '请输入评价名称', trigger: 'blur' }]
                    },
                    {
                        key: 'time',
                        label: '评价时间',
                        component: 'a-range-picker',
                        props: {
                            showTime: {format: 'HH:mm'},
                            format: 'YYYY-MM-DD HH:mm',
                            valueFormat: 'YYYY-MM-DD HH:mm',
                            placeholder: ['开始时间', '截止时间']
                        },
                        rules: [{ required: true, message: '请设置评价时间范围', trigger: 'change' }]
                    },
                ]
            }
        },
        methods: {
            generate() {
                if(this.loading) return;
                this.form = {title: this.info.name};
                this.visible = true;
            },
            confirmGenerate() {
                this.$refs.generateForm.handleConfirm().then(form => {
                    this.loading = true;
                    this.$axios({
                        url: '/admin/evaluate-program/generate',
                        method: "POST",
                        data: {
                            title: form.title,
                            begin_time: form.time[0],
                            end_time: form.time[1],
                            id: this.info.id
                        }
                    }).then(res => {
                        this.loading = false;
                        if(res.error == 0) {
                            this.$message.success("已生成评价");
                        } else {
                            this.$message.warning(res.msg);
                        }
                    }).catch(() => {
                        this.loading = false;
                    });
                    this.visible = false;
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .hr-program-other {
        .other-title {
            width: 120px;
            margin: 0 20px 0 0;
        }
    }
</style>
