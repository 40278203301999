import {getKeyTitle} from "@/common/js/tool";
import {clone} from "../js/tool";

export const tempTypes = [
    {key: 1, title: '请假'},
    {key: 2, title: '安全上报'},
    {key: 3, title: '汇报'},
    {key: 4, title: '殿堂'},
    {key: 5, title: '会议室'},
    {key: 6, title: '牌位'},
    {key: 7, title: '用餐'},
    {key: 8, title: '上香'},
    {key: 9, title: '礼品'},
    {key: 10, title: '公章'},
    {key: 11, title: '法务'},
    {key: 12, title: '用车'},
    {key: 13, title: '车辆报备'},
    {key: 14, title: '文宣'},
    {key: 15, title: '来访'},
    {key: 16, title: '佛事法会'},
    {key: 17, title: '活动'},
    {key: 18, title: '申购'},
    {key: 19, title: '领用'},
    {key: 20, title: '报修'},
    {key: 21, title: '计划'},
    {key: 22, title: '费用'},
    {key: 23, title: '补贴'},
    {key: 24, title: '报废'},
    {key: 25, title: '打卡'},
    {key: 26, title: '加班'},
    {key: 27, title: '外出'},
    {key: 28, title: '补卡'},
    {key: 43, title: '借用'},
    {key: 44, title: '借用延期'},
    {key: 45, title: '安全申请'},
    {key: 46, title: '安全'},
    {key: 48, title: '公共区域日常巡逻'},
    {key: 49, title: '突发事件处理'},
    {key: 50, title: '投诉'},
    {key: 51, title: '斋堂日常卫生检查'},
    {key: 52, title: '设备、工具清洗记录'},
    {key: 53, title: '设备维护保养记录'},
    {key: 54, title: '食堂消毒记录'},
    {key: 55, title: '消防培训'},
    {key: 56, title: '消防演习'},
    {key: 57, title: '维保单'},
    {key: 58, title: '维保合同'},
];

// 志工管理的应用类型
export const volunteerTempTypes = [
    {key: 29, title: '文件通知'},
    {key: 30, title: '组长日志'},
    {key: 31, title: '志工面试'},
    {key: 32, title: '志工转正'},
    {key: 33, title: '志工已故'},
    {key: 34, title: '志工开除'},
    {key: 35, title: '志工劝退'},
    {key: 36, title: '志工转组'},
    {key: 37, title: '重病关怀'},
    {key: 38, title: '发起志工服务'},
    {key: 39, title: '志工退出'},
    {key: 40, title: '志工休眠激活'},
    {key: 41, title: '70周岁以上志工参加日常组志工服务'},
    {key: 42, title: '报名审核'},
    {key: 47, title: '志工注册'},
]

const tempGroups = [
    {key: 1, title: '基础应用'},
    {key: 2, title: '出勤评价'},
    {key: 3, title: '流程审批'},
    {key: 4, title: '特色应用'},
    {key: 5, title: '场所预约'},
    {key: 6, title: '消防安全'},
    {key: 7, title: '资产管理'},
    {key: 9, title: '智能场景'},
    {key: 10, title: '客堂'},
    {key: 11, title: '斋堂'},
    {key: 12, title: '工程部'},
]

export const volunteerTempGroups = [
    {key: 8, title: '志工管理'},
]

export const announcement = {
    name: '公告',
    icon: "announcement.png",
    type: 'announcement',
    id: -2,
}

export const getTempGroupName = function (group) {
    return getKeyTitle([...tempGroups, ...volunteerTempGroups], group);
}

// 不能修改表单的应用类型
export const formFixedTempTypes = [4, 5, 8, 13, 18, 19, 24];

// 不能删除的应用类型
export const unDeleteTempTypes = ["announcement",25,26,27,28,29,30];

export const getTempName = function (val) {
    return getKeyTitle([...tempTypes, ...volunteerTempTypes], val);
}

export const hrTempTypes = [
    {key: 1, title: '进单'},
    {key: 2, title: '离单'},
    {key: 3, title: '入职'},
    {key: 4, title: '离职'},
    {key: 5, title: '调岗'},
]

export const hrVolunteerTempTypes = [
    {key: 6, title: '申请转组'},
    {key: 7, title: '申请退出'},
    {key: 8, title: '申请休眠激活'},
    {key: 9, title: '申请修改个人注册信息'},
    {key: 10, title: '70周岁以上的志工申请参加日常组志工服务'},
]

export const getHrVolunteerTempTypes = function (type) {
    return getKeyTitle(hrVolunteerTempTypes, type);
}

export const getHRTempTypeName = function (type) {
    return getKeyTitle(hrTempTypes, type);
}

export const tempItems = [
    {
        key: 'name',
        label: '应用名称',
        component: 'a-input',
        props: {
            placeholder: '请输入应用名称',
        },
        rules: [{ required: true, message: '请输入应用名称', trigger: 'blur' }]
    },
    {
        key: 'icon',
        label: '应用图标',
        component: 'icon-select',
        props: {
            placeholder: '请选择应用图标',
        },
        rules: [{ required: true, message: '请选择应用图标', trigger: 'change' }]
    },
    {
        key: 'type',
        label: '应用类别',
        component: 'a-select',
        props: {
            placeholder: '请选择应用类别',
            options: tempTypes
        },
        rules: [{ required: true, message: '请选择应用类别', trigger: 'change' }]
    },
    {
        key: 'oa_type',
        label: '应用分组',
        component: 'a-select',
        props: {
            placeholder: '请选择应用分组',
            options: tempGroups
        },
        rules: [{ required: true, message: '请选择应用分组', trigger: 'change' }]
    },
]

export const getTempMenu = function (temp) {
    let res;
    if(temp.is_oa == 1 || temp.is_oa == 3) {
        let base = '/temp';
        res = [
            {
                title: '数据统计',
                path: `${base}/${temp.id}/data`,
                icon: 'table'
            },
            {
                title: '模板设置',
                path: `${base}/${temp.id}/form`,
                icon: 'profile'
            },
            {
                title: '规则权限',
                path: `${base}/${temp.id}/setting`,
                icon: 'setting'
            }
        ];
        switch (temp.type) {
            case 1:
                // 假期设置
                res.push(
                    {
                        title: '假期设置',
                        path: '/entity/holiday',
                        icon: 'schedule'
                    },
                    {
                        title: '假期余额',
                        path: `/temp/${temp.id}/holiday-balance`,
                        icon: 'schedule'
                    },
                    {
                        title: '请假记录',
                        path: `/temp/${temp.id}/leave-record`,
                        icon: 'schedule'
                    },
                );
                break;
            case 4:
                // 场所管理
                res.push({
                    title: '场所管理',
                    path: '/entity/place',
                    icon: 'home'
                });
                break;
            case 5:
                // 会议室管理
                res.push({
                    title: '会议室管理',
                    path: '/entity/room'
                });
                break;
            case 7:
                // 包厢管理
                res.push({
                    title: '包厢管理',
                    path: '/entity/box'
                });
                break;
            case 12:
                // 车辆管理
                res.push({
                    title: '车辆管理',
                    path: '/entity/car'
                });
                break;
            case 13:
                // 车辆报备
                res.push({
                    title: '车牌下发',
                    path: `/temp/${temp.id}/plate`,
                    icon: 'safety'
                });
                break;
            case 21:
                res = [{
                    title: '权限设置',
                    path: `/temp/${temp.id}/setting`,
                    icon: 'setting'
                }];
                break;
        }
    } else if (temp.is_oa == 5) {
        let base = '/hr/volunteer/app/volunteerTemp';
        let specialType = [29, 30];
        // 志工
        if (specialType.includes(temp.type)) {
            res = [{
                title: '应用设置',
                path: `${base}/${temp.id}/setting`,
                icon: 'setting'
            }];
            switch (temp.type) {
                case 29:
                    res.unshift({
                        title: '文件列表',
                        path: `${base}/${temp.id}/file-notice`
                    })
                    break;
                case 30:
                    res.unshift({
                        title: '日志列表',
                        path: `${base}/${temp.id}/team-leader-log`
                    })
                    break;
                default: res.unshift({
                    title: '数据统计',
                    path: `${base}/${temp.id}/data`,
                    icon: 'table'
                },)
            }
        } else if (temp.type == 42) {
            // 报名审核
            res = [
                {
                    title: '规则权限',
                    path: `${base}/${temp.id}/setting`,
                    icon: 'setting'
                }
            ]
        } else {
            res = [
                {
                    title: '数据统计',
                    path: `${base}/${temp.id}/data`,
                    icon: 'table'
                },
                {
                    title: '模板设置',
                    path: `${base}/${temp.id}/form`,
                    icon: 'profile'
                },
                {
                    title: '规则权限',
                    path: `${base}/${temp.id}/setting`,
                    icon: 'setting'
                }
            ];
        }

    }
    return res;
}

// 志工应用，去除应用类别，更换分组选项
export const getVolunteerTempFormItems = function(items) {
    let typeIndex = items.findIndex(item => item.key == 'type');
    items[typeIndex].props.options = volunteerTempTypes;
    let oaTypeIndex = items.findIndex(item => item.key == 'oa_type');
    items[oaTypeIndex].props.options = volunteerTempGroups;
}

export const getTempFormItems = function (temp) {
    const items = clone(tempItems);
    if(temp.is_oa == 3) {
        items.push({
            key: 'extraTemp',
            label: '接待需求',
            component: 'child-temp',
            props: {}
        });
    } else if (temp.is_oa == 5) {
        getVolunteerTempFormItems(items);
    }
    return items
}
